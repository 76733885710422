// style
import './../css/about.css'
// react section
import {Fragment, lazy, useEffect, useState} from "react";
// seo
import {Seo} from "../../../layout/seo/seo";
// link
import {routes} from "../../../../original/route/route";
// axios
import axios from "axios";
// icons
import {
    IconBrandInstagram,
    IconBrandTelegram,
    IconBrandWhatsapp, IconPhone,
} from "@tabler/icons-react";
import {Banner} from "../../../layout/banner/page/banner";
// image
import banner from "./../../../../original/image/about.webp";
import LazyLoad from "react-lazy-load";
// component
const Worker = lazy(() => import('./child/worker').then(module => ({default: module.Worker})))

export const About = () => {
    const [workers, setWorkers] = useState([{}])
    useEffect(() => {
        axios.get(`${routes[5].address}workers`)
            .then((response) => {
                setWorkers(response.data.data);
            })
            .catch((error) => {
                if (error.response) {

                }
            })
    }, []);
    return (
        <Fragment>
            <Seo
                title="عمارت سران | درباره ما"
                description="کافه رستوران عمارت سران بهترین انتخاب برای گذراندان اوقات شریفتان در فضایی زیبا به همراه بهترین متریال ها و لذیذ ترین غدا ها که مبنای سعیش جذب رضایت کامل مشتری و عزیزان است !"
                keywords="عمارت سران،کافه رستوران،پرسنل،همراهان،دکوراسیون،شبکات اجتماعی"
                type="webapp"
                name="progogram"
            />
            <section id="about" className="container">
                <Banner image={banner}
                        title={'درباره ما'}
                        description={
                            `لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان
                             گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است`
                        }
                        link={routes[3].address}
                        link_text={'منو'}/>
                <div id="about-description" className="mt-5">
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-md-6 mb-md-0 mb-3">
                            <LazyLoad>
                                <img loading={'lazy'} alt="description"
                                     src={require('./../../../../original/image/about-des.png')}
                                     className="img-fluid animated-img"/>
                            </LazyLoad>
                        </div>
                        <div className="col-lg-7 col-md-6 mb-md-0 text-md-end text-center" data-aos="fade-down"
                             data-aos-duration="2000">
                            <p className="h2 fw-bold card-title text-md-end text-center">داستان تاسیس</p>
                            <LazyLoad className="text-md-end text-center">
                                <img loading={'lazy'} alt="asset"
                                     src={require('./../../../../original/icon/Asset-12 1.png')}
                                     className="img-fluid divider mt-2"/>
                            </LazyLoad>
                            <p className="card-text mt-2">
                                کافه رستوران عمارت سران با فضایی دلنشین و دکوراسیونی جذاب، مکانی ایده‌آل برای دورهمی‌های
                                دوستانه و خانوادگی شماست.
                                منوی متنوع ما شامل انواع غذاهای ایرانی، فرنگی، دریایی و فست فود، با بهترین مواد اولیه و
                                توسط سرآشپزهای مجرب تهیه شده تا هر سلیقه‌ای را راضی کند.
                                علاوه بر غذاهای لذیذ، می‌توانید از انواع نوشیدنی‌های گرم و سرد، کیک و دسرهای خوشمزه در
                                کافه ما لذت ببرید.
                            </p>
                        </div>
                    </div>
                </div>
                <div id="workers" className="mt-5 text-md-end text-center">
                    <p className="h2 text-site fw-bold text-md-end text-center mt-5">پرسنل و همراهان</p>
                    <LazyLoad className="text-md-end text-center">
                        <img loading={'lazy'} alt="asset" src={require('./../../../../original/icon/Asset-12 1.png')}
                             className="img-fluid divider mt-2"/>
                    </LazyLoad>
                    <div className="row">
                        {workers.map((worker, index) => (
                            <div className="col-md-4 my-3" data-aos="fade-up" data-aos-duration="2000" key={index}>
                                <Worker title={worker.name} description={worker.description} image={worker.image}/>
                            </div>
                        ))}
                    </div>
                </div>
                <div id="conect-description">
                    <div className="row align-items-center">
                        <div data-aos="fade-down" data-aos-duration="2000"
                             className="col-lg-7 ps-5 order-md-0 order-1 col-md-6 mb-md-0 text-md-end text-center">
                            <p className="h2 h2 fw-bold card-title text-md-end text-center">شبکات اجتماعی</p>
                            <LazyLoad className="text-md-end text-center">
                                <img loading={'lazy'} alt="asset"
                                     src={require('./../../../../original/icon/Asset-12 1.png')}
                                     className="img-fluid divider mt-2"/>
                            </LazyLoad>
                            <p className="card-text mt-2">
                                شما می توانید با ما در شبکات اجتماعی در ارتباط باشید و تجربیات خود را با ما به اشتراک
                                بگذارید:
                            </p>
                            <div className="d-flex justify-content-md-start justify-content-center">
                                <button className="btn btn-outline-site ms-2">
                                    <IconBrandInstagram/>
                                </button>
                                <button className="btn btn-outline-site ms-2">
                                    <IconBrandTelegram/>
                                </button>
                                <button className="btn btn-outline-site ms-2">
                                    <IconBrandWhatsapp/>
                                </button>
                                <button className="btn btn-outline-site ms-2">
                                    <IconPhone/>
                                </button>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6 order-md-1 order-0 mb-md-0 mb-3">
                            <LazyLoad>
                                <img loading={'lazy'} alt="description"
                                     src={require('./../../../../original/image/about-social.png')}
                                     className="img-fluid animated-img"/>
                            </LazyLoad>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}